/*
 * Copyright (C) 2015 - 2018 Kosmos contact@kosmos.fr
 *
 * Projet: frontgen
 * Version: 6.07.24
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *         http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
(function($){
    // Delegate .transition() calls to .animate()
    // if the browser can't do CSS transitions.
    if (!$.support.transition) $.fn.transition = $.fn.animate;

    /**
     * Locale de la page courante
     */
    var locale = $('html').attr('lang');

    /**
     * Initialisation des champs de type date
     */
    $('.type_date').datepicker($.datepicker.regional[locale]);

    $('.js-force-reload').click(function() {
        window.location.reload(true);
    });

    // Fonctionnalité de visualisation d'une image
    $('a[data-imgmagnifier]').magnificPopup({
        type: 'image'
    });

    // Si on clique n'importe où sur le plan, on ferme tous les menus ouverts intégrés
    $('.plan__carte-svg').on('click', function (e) {
        [].forEach.call(document.querySelectorAll('.city--active'), function (item) {
            removeSvgClass(item, 'city--active');
        });
    });

    // Si on clique sur un lien avec un menu déroulant, on le déroule s'il est fermé
    $('.city--link').on('click', function(e){
        let elementId = this.id+'-content';
        [].forEach.call(document.querySelectorAll('.city--active:not(#'+elementId+')'), function (item) {
            removeSvgClass(item, 'city--active');
        });
        toggleSvgClass(document.getElementById(elementId), 'city--active');
        e.stopPropagation();
    });

    // Si on clique sur le contenu d'un menu déroulant, on ne déclenche pas les actions globales (comme celle sur le plan qui ferme tous les menus ouverts)
    $('.city-content').on('click', function (e) {
        e.stopPropagation();
    });

    function toggleSvgClass(element, className) {
        if (hasSvgClass(element, className)) {
            removeSvgClass(element, className);
        }
        else {
            addSvgClass(element, className);
        }
    }

    function addSvgClass(element, className) {
        var existingClassContent = element.getAttribute('class');
        var existingClassList = existingClassContent.split(' ');
        var newClassList = existingClassList;
        if (existingClassList.indexOf(className) === -1) {
            newClassList.push(className);
        }
        element.setAttribute('class', newClassList.join(' '));
    }

    function removeSvgClass(element, className) {
        var existingClassContent = element.getAttribute('class');
        var existingClassList = existingClassContent.split(' ');
        var newClassList = existingClassList;
        if (existingClassList.indexOf(className) !== -1) {
            newClassList = existingClassList.filter(function (item) { return item !== className; });
        }
        element.setAttribute('class', newClassList.join(' '));
    }

    function hasSvgClass(element, className) {
        var existingClassContent = element.getAttribute('class');
        var existingClassList = existingClassContent.split(' ');
        return existingClassList.indexOf(className) !== -1;
    }

    $('#VALIDATION_CAPTCHA').on('input', function() {
        $(this).val($(this).val().toLowerCase());
    });

})(jQuery.noConflict());
